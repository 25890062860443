import React from "react";

import "./style.scss";
import { Contact, LightBox, Squiggle } from "../../components";
import { getImage, parseToHtml } from "../../helper";

export const ParentsLayout = ({
  title = "",
  contact = "",
  html = "",
  content = "",
  parents = [],
}) => {
  const activeParents = parents?.filter(parent => !parent?.retired);
  const retiredParents = parents?.filter(parent => parent?.retired);

  return (
    <div className="parents">
      {title && <h1>{title}</h1>}
      {(content || html) && (
        <div className="data">{parseToHtml(content || html)}</div>
      )}
      {activeParents?.map((parent, index) => (
        <Parent key={index} {...parent} />
      ))}
      <Squiggle />
      {retiredParents?.length ? <h2>Retired Parents</h2> : null}
      {retiredParents?.map((parent, index) => (
        <Parent key={index} {...parent} />
      ))}
      <Contact image={contact} />
    </div>
  );
};

const Parent = ({
  about = "",
  akc = "",
  call = "",
  main = "",
  name = "",
  retired = false,
  supporting = [],
  weight = "",
  color = "",
}) => {
  const _color = name === "Pink" ? "pink" : name === "Blue" ? "blue" : "";

  return (
    <div className="parent">
      {retired ? null : <Squiggle />}

      {akc && <h3 className={`alexBrush ${_color}`}>{akc}</h3>}
      {call && <h4 className={`alexBrush ${_color}`}>"{call}"</h4>}
      {main && (
        <div className="main">
          <img src={getImage(main)} alt="main" />
        </div>
      )}
      {!retired && supporting?.length ? (
        <LightBox>
          <div className="supporting">
            {supporting?.map((item, index) => (
              <a key={index} href={getImage(item)}>
                <img src={getImage(item)} alt={akc} />
              </a>
            ))}
          </div>
        </LightBox>
      ) : null}
      {about && <div className="info">{parseToHtml(about)}</div>}
      {!retired && weight && <div className="info">Adult Weight: {weight}</div>}
      {!retired && color && <div className="info">{color}</div>}

      {retired ? <Squiggle /> : null}
    </div>
  );
};
