import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { Layout } from "../components";
import { getContent } from "../helper";
import { ParentsLayout } from "../layouts/Parents";

const Parents = props => {
  const data = getContent(props?.data);

  return (
    <Layout title="Parents" {...props}>
      <Helmet>
        <meta
          name="description"
          content="Selected for their excellent temperament, healthy lines and beautiful faces, come see what our AKC registered Imperial Shih Tzu parents are all about!"
        />{" "}
        <meta
          name="keywords"
          content="Imperial Shih Tzu Puppies for Sale, shih tzu puppies for sale los angeles, shih tzu for sale san diego, Teacuo Shih Tzu Puppies For Sale, AKC Shih Tzu Puppies for sale, Shih Tzu Puppies for Sale, Tiny Shih Tzu Puppies for sale, Shih Tzu Puppies for sale in Fullerton, Shih Tzu Puppies for sale in Irvine, Shih Tzu Puppies for sale in Newport Beach, Shih Tzu Puppies for sale in Los Angeles, Shih Tzu Puppies for sale in Mission Viejo, Shih Tzu Puppies for sale in Lake Forest, Shih Tzu Puppies for sale in Brea, Shih Tzu Puppies For Sale in La Habra Heights, Shih Tzu Puppies for Sale in Costa Mesa, Shih Tzu Puppies for sale in Las Vegas, Shih Tzu Puppies for Sale in Anaheim Hills"
        />
      </Helmet>
      <ParentsLayout {...data} />
    </Layout>
  );
};

export default Parents;

export const query = graphql`
  query {
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/parents/" } }) {
      nodes {
        html
        frontmatter {
          title
          contact
          content
          parents {
            akc
            call
            name
            main
            supporting
            weight
            color
            retired
            about
          }
        }
      }
    }
  }
`;
